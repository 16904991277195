<template>
  <div class="about">
    <h4>とある魔術の禁書目録 幻想収束 辅助工具集</h4>
    <h4>
      目前还有6张卡缺6星卡图，其余卡图已全。如果你对现用卡图不满意也欢迎提供更高质量卡图，详情请查看卡图征集页面。
    </h4>
    <h4>
      已汉化404张卡（2021.12.30）。
      <a href="https://paratranz.cn/projects/1209/files">参与汉化</a>
    </h4>
    <h4>
      <a href="https://twitter.com/Skywalker_Ji">Twitter</a>
    </h4>
    <h4>
      <a href="https://github.com/SkywalkerJi/toaruif-tools">GitHub</a>
    </h4>
    <h4>
      <a href="https://t.me/toaru_project">Telegram</a>
    </h4>
      <HelloI18n></HelloI18n>
  </div>
</template>

<script>
import HelloI18n from "../components/HelloI18n.vue";
export default {
  components: {
    HelloI18n,
  },
};
</script>